<template>
	<div class="my-5">
		<v-container>
			<v-row>
				<v-col cols="12" md="4" class="mt-md-8">
					<div class="secondary--text">We are happy to have you!</div>
					<div class="text-h4 font-weight-medium primary--text">Manage and grow <br /> your finance with us<span class="secondary--text">.</span></div>
					<div class="mt-5 d-none d-md-block d-lg-block">
						<img src="@/assets/icons/reg_auth.png" alt="Authentication" width="350px">
					</div>
				</v-col>
				<v-col cols="1"></v-col>
				<v-col cols="12" md="7">
					<v-card class="py-10 px-5 px-md-8 auth-box lighten-5 customized-form rounded-xl" outlined>
						<div class="text-center">
							<div class="text-h5">Create your Account</div>
							<small>Welcome to the future of E-payments &amp; Savings </small>
						</div>

						<v-form ref="login" class="py-10" v-model="valid" lazy-validation>
							<v-row>
								<v-col cols="12" md="6" class="py-0">
									<p class="label">First Name</p>
									<v-text-field
										outlined
										placeholder="Last name here"
										v-model="form.firstname"
										required dense solo
										:rules="[(v) => !!v || 'First name is required']"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<p class="label">Last Name</p>
									<v-text-field
										outlined solo
										placeholder="Last name here"
										v-model="form.lastname"
										required dense
										:rules="[(v) => !!v || 'Last name is required']"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<p class="label">Phone number</p>

									<vue-tel-input-vuetify
										:enabled-country-code="true"
										v-on:country-changed="countryChanged"
										:valid-characters-only="true"
										v-model="form.phone"
										outlined dense solo
										autocomplete="off"
										v-on:keydown="validNumber($event)"
									></vue-tel-input-vuetify>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<p class="label">Email</p>
									<v-text-field
										outlined solo
										placeholder="john@example.com"
										v-model="form.email"
										required dense
										:rules="emailRules"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<p class="label">Password</p>
									<v-text-field
										outlined solo
										placeholder="Enter password"
										v-model="form.password"
										type="password"
										required dense
										:rules="passwordRules"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<p class="label">Confirm Password</p>
									<v-text-field
										outlined
										placeholder="Re-enter password"
										v-model="form.confirm_password"
										type="password"
										required dense solo
										:rules="passwordRules2"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" class="py-0">
									<v-btn
										block
										large
										class="primary auth-btn"
										@click="$refs.login.validate() ? register() : null"
										:loading="loading"
										>Register</v-btn
									>
								</v-col>
							</v-row>
						</v-form>
						
						<div class="text-center">
							<small class=""
								>Already have an account?
								<router-link to="/login"> Login here</router-link></small
							>
						</div>
					</v-card>					
				</v-col>
			</v-row>
		</v-container>
		
		<v-dialog v-model="dialog" width="400" @click:outside="dialog = true" persistent>
			<v-card class="pa-6 form-colored-bg">
			<div class="text-center">
				<h4>OTP Verification</h4>
				<p class="small mb-0 pb-0" style="font-size: 15px; line-height: 25px;" >Please enter the 5-digit OTP that was sent to your phone number and email.</p>
				<p><small><i>Didn't receive email? Check your spam folder for your OTP</i></small></p>
			<PincodeInput v-model="otp" :length="5" class="my-12" :secure="true" />
			</div>


			<v-btn class="primary" block :loading="loading" @click="verifyOtp()" large>Verify OTP</v-btn>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import PincodeInput from 'vue-pincode-input'
export default {
	components: {
		PincodeInput
	},
	data() {
		return {
			valid: true,
			loading: false,
			dialog: false,
			otp: '',
			phone: '',
			user: [],
			emailRules: [
				(v) => !!v || 'E-mail is required',
				(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			passwordRules: [
				(v) => !!v || 'Password number is required',
				(v) => v.length >= 6 || 'Password must be at least six characters',
			],
			passwordRules2: [
				(v) => !!v || 'Password Confirmation is required',
				(v) => v == this.form.password || 'Password must match',
			],
			form: {
				password: '',
			},
			phone_error: '',
			country: null,
		}
	},
	methods: {
		async register() {
			this.loading		= true
			this.form.name 		= this.form.firstname + ' ' + this.form.lastname
			this.$route.params ? this.form.ref_code = this.$route.params.ref_code : ''
			this.form.phone 	= (!this.form.phone.includes('+') ? this.country + this.form.phone : this.form.phone);
			this.form.platform	= 'web'
			try {
				let res = await this.$store
				.dispatch('auth/register', this.form)
				this.$toast.success('Your Registration was successful!')
				this.loading = false
				this.user = res
				this.dialog = true
			}

			catch (err) {
				this.loading = false
				if(err.response && err.response.status == 400) {
					this.$toast.error(
						err.response.data.email ?
						err.response.data.email[0] :
						err.response.data.phone ? 
						err.response.data.phone[0] :
						''
					)
				}
			}
		},

		async verifyOtp() {
			this.loading = true
			const data = {
				otp: this.otp,
				user_id: this.user.id
			}
			try {
				let response = await this.$store.dispatch('auth/validateotp', data)
				this.$toast.success(response.message)
				this.loading = false
				this.$router.push('/login')
			}
			
			catch (err) {
				this.$toast.error(err.response ? err.response.data.errors : '')
				this.loading = false
			}
		},

		countryChanged(country) {
			this.country = '+' + country.dialCode
		},

		validNumber(e) {
			e.target.value = e.target.value.replace(/[^0-9]+/g, '');
		}
	},
}
</script>
<style>
.customized-form .v-input__slot, .customized-form .vue-tel-input {
	box-shadow: none !important;
}
</style>
<style lang="scss" scoped>
.auth-box {
//	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	h4 {
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-size: 30px !important;
		line-height: 52px !important;
		color: #3a3838 !important;
	}
	small {
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-size: 14px !important;
		line-height: 27px !important;
		color: #3a3838 !important;
	}
	p.label {
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-size: 15px !important;
		line-height: 13px !important;
		color: #171b70 !important;
	}
	}
	.auth-btn {
		background: #171b70 !important;
		border: 0.5px solid #171b70 !important;
		box-shadow: 0px 1px 4px 1px rgba(66, 70, 149, 0.45) !important;
		border-radius: 5px !important;
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 20px !important;
		line-height: 37px !important;
		text-transform: capitalize !important;
	}
	a {
		text-decoration: none !important;
	}
	@media (min-width: 810px){
		.hello {
			margin-top: -250px !important;
		}
	}
	.v-text-field input {
		font-size: 15px !important;
	}
</style>
